<template>
  <div id="employeesOnHoliday">
    <v-card max-width="100%" class="px-4 py-2 employee-holidays">
      <h3 class="main-color card-title">{{ $t("Employees on holidays") }}</h3>

      <v-data-table
        :headers="holidaysheaders"
        :items="items"
        :items-per-page="5"
        class="elevation-1 holidays-table"
        hide-default-footer
      >
        <template v-slot:item.Employee="{ item }">
          <div class="d-flex align-center">
            <v-img
              max-width="30px"
              class="d-inline-block mx-1"
              :src="item.image ? item.image : '../../assets/avatar.png'"
            />
            <span style="color: #8d8a8a; padding-left: 10px"
              >{{ item.Employee }} <br />
              {{ item.jobTitle }}</span
            >
          </div>
        </template>
        <template v-slot:item.days="{ item }">
          <span style="color: #8d8a8a"
            >{{ item.days }} <br />
            {{ item.vacation_name }}</span
          >
        </template>
        <template v-slot:item.date="{ item }">
          <v-icon color="green" small>calendar_today</v-icon>
          <span style="color: green">{{ item.date }}</span>
        </template>
      </v-data-table>

      <div class="text-center pt-2">
        <v-pagination
          v-if="length > 0"
          v-model="page"
          :length="length"
          :circle="circle"
          :page="page"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "EmployeesOnHolidays",
  data() {
    return {
      holidaysheaders: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Employee",
        },
        { text: "", value: "days" },
        { text: "", value: "date" },
      ],
      items: [],
      circle: true,
      page: 1,
      length: 0,
    };
  },
  watch: {
    page: {
      handler() {
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      axios
        .get(
          this.getApiUrl + "/requestleave/employeeOnHoliday?page=" + this.page,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.items = response.data.data.data;

          this.page = response.data.data.current_page;
          this.length = response.data.data.last_page;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.employee-holidays {
  height: 100% !important;
}
.employee-holidays {
  .avatar {
    border-radius: 50%;
  }
  p,
  span {
    font-size: 12px !important;
  }
}
</style>

<template>
  <v-card id="hrLeaveRequests" max-width="100%" class="px-4 py-2 leave-request">
    <v-row>
      <v-col>
        <h3 class="main-color card-title">{{ $t("Leave Requests") }}</h3>
      </v-col>

      <v-col class="text-right">
        <label class="search" for="inpt_search">
          <input
            id="inpt_search"
            type="text"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model="search"
          />
        </label>

        <span title="Filter">
          <v-menu
            v-model="filterMenu"
            offset-y
            :close-on-content-click="false"
            :nudge-width="300"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <i v-on="on" class="fas fa-filter main-color fa-lg"></i>
            </template>

            <v-list>
              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.types"
                  :label="$i18n.t('Type')"
                  :items="typesItems"
                  @change="hideLabel = true"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.jobs"
                  :label="$i18n.t('Job Title')"
                  :items="jobsItems"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.reasons"
                  :label="$i18n.t('Vacation Reasons')"
                  :items="reasonsItem"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.permissionReason"
                  :label="$i18n.t('Permission Reasons')"
                  :items="permissionReasonsItem"
                  @change="hideLabel = true"
                  item-text="name"
                  item-value="id"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>

              <v-list-item class="select">
                <v-autocomplete
                  v-model="filter.status"
                  :label="$i18n.t('Status')"
                  item-text="text"
                  item-value="value"
                  :items="statusItems"
                  @change="hideLabel = true"
                  multiple
                  solo
                ></v-autocomplete>
              </v-list-item>
              <v-list-item class="select">
                <v-select
                  :label="$i18n.t('Year')"
                  v-model="filter.selectedYear"
                  item-text="year"
                  item-value="year"
                  :items="years"
                  @change="hideLabel = true"
                  solo
                ></v-select>
                <v-select
                  :label="$i18n.t('Month')"
                  v-model="filter.selectedMonth"
                  :items="months"
                  item-text="month"
                  item-value="id"
                  @change="hideLabel = true"
                  solo
                ></v-select>
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center">
        <span class="custom-filter" @click="getData(page, 'current')">{{
          $t("Current")
        }}</span>
        <span class="custom-filter" @click="getData(page, 'past')">{{
          $t("Past")
        }}</span>
        <span class="custom-filter active" @click="getData(page, 'all')">{{
          $t("All")
        }}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:item.employee="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.employee.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.employee }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.job_title="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on">
                  {{ item.job_title.slice(0, 10) }}
                </p>
              </template>
              <span>{{ item.job_title }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.status="{ item }">
            <span :class="item.status_class">{{ item.status }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              @click="openAproveDialog(item)"
              v-if="item.status == 'Pending'"
              title="Approved"
              style="cursor: pointer"
              >check_circle</v-icon
            >

            <v-icon
              v-else-if="item.status == 'Approved' || item.status == 'Declined'"
              @click="openUnApprove(item)"
              >check_circle</v-icon
            >
            <v-icon v-else disabled>check_circle</v-icon>
            <v-icon
              @click="openAproveDialog(item)"
              v-if="item.status == 'Pending'"
              title="Approved"
              style="cursor: pointer"
              >cancel</v-icon
            >

            <v-icon
              v-else-if="item.status == 'Approved' || item.status == 'Declined'"
              @click="openUnApprove(item)"
              >cancel</v-icon
            >
            <v-icon v-else disabled>cancel</v-icon>
            <!-- <v-icon v-else title="Declined" disabled>cancel</v-icon> -->
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
          </v-snackbar>
        </div>
      </v-col>
    </v-row>

    <!-- modal approve type vacation -->
    <v-dialog v-model="approveDialog" persistent max-width="500px" scrollable>
      <v-card>
        <v-card-text>
          <v-container>
            <!-- Request Tab -->
            <v-row>
              <v-row>
                <v-col>
                  <h3 class="main-color mb-0" style="font-weight: 600">
                    {{ $t("Leave Request") }}
                  </h3>
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row v-if="duration != ''">
                <v-col>
                  <div class="days">
                    <span class="dayUsed"
                      >{{ days + " / " + duration }} {{ $t("Days Used") }}</span
                    >

                    <span class="dayRemaining"
                      >{{ remaining }} {{ $t("Days Remaining") }}</span
                    >
                  </div>
                </v-col>
              </v-row>
              <v-form v-model="valid" ref="form" style="width: 100%">
                <v-row>
                  <v-col class="pt-0">
                    <v-radio-group
                      v-model="approvetObject.apptoveType"
                      row
                      style="width: 100%"
                    >
                      <v-col cols="12" md="3">
                        <v-radio
                          :label="$i18n.t('Paid')"
                          value="paid"
                        ></v-radio>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-radio
                          :label="$i18n.t('Unpaid')"
                          value="unpaid"
                        ></v-radio>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-radio
                          label="Partialy Paid"
                          value="partialy_paid"
                        ></v-radio>
                      </v-col>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <label for="contact-name">{{ $t("# Paid Days") }}</label>
                    <v-text-field
                      v-model="approvetObject.paid_days"
                      :rules="[validationRules.required]"
                      solo
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0">
                    <label for="Notes">{{ $t("Notes") }}</label>

                    <v-textarea
                      rows="3"
                      v-model="approvetObject.approve_notes"
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- @click="close" -->
          <v-btn class="modal-btn-cancel" @click="closeDialog">{{
            $t("Close")
          }}</v-btn>

          <v-btn
            style="border: 2px solid red; color: red"
            color="danger"
            outlined
            @click="reject(approvetObject.id)"
            >{{ $t("Reject") }}</v-btn
          >
          <v-btn class="modal-btn-save" @click="approve()">{{
            $t("Approve")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modal approve type permission -->
    <v-dialog
      v-model="approvePermissionDialog"
      persistent
      max-width="450px"
      scrollable
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h3 class="main-color mb-0" style="font-weight: 600">
                    {{ $t("Permission Request") }}
                  </h3>
                </v-col>
                <v-col></v-col>
              </v-row>

              <v-form v-model="valid" ref="form" style="width: 100%">
                <v-row>
                  <v-col class="pt-0">
                    <v-radio-group
                      v-model="approvetObject.apptoveType"
                      row
                      style="width: 100%"
                    >
                      <v-col cols="12" md="5">
                        <v-radio
                          :label="$i18n.t('Paid')"
                          value="paid"
                        ></v-radio>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-radio
                          :label="$i18n.t('Unpaid')"
                          value="unpaid"
                        ></v-radio>
                      </v-col>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pt-0">
                    <label for="Notes">{{ $t("Notes") }}</label>

                    <v-textarea
                      rows="3"
                      v-model="approvetObject.approve_notes"
                      solo
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- @click="close" -->
          <v-btn class="modal-btn-cancel" @click="closeDialog">{{
            $t("Close")
          }}</v-btn>

          <v-btn
            style="border: 2px solid red; color: red"
            color="danger"
            outlined
            @click="reject(approvetObject.id)"
            >{{ $t("Reject") }}</v-btn
          >
          <v-btn class="modal-btn-save" @click="approve()">{{
            $t("Approve")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- modal UnApprove -->
    <v-dialog v-model="unApproveDialog" persistent max-width="300px" scrollable>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <h3
                  v-if="item_status == 'Declined'"
                  class="main-color mb-0"
                  style="font-weight: 600"
                >
                  {{ $t("Unreject Request") }}
                </h3>
                <h3
                  v-if="item_status == 'Approved'"
                  class="main-color mb-0"
                  style="font-weight: 600"
                >
                  {{ $t("Unapproved Request") }}
                </h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- @click="close" -->
          <v-btn class="modal-btn-cancel" @click="closeDialogUnApprove">{{
            $t("Close")
          }}</v-btn>

          <v-btn
            style="border: 2px solid red; color: red"
            color="danger"
            outlined
            v-if="item_status == 'Declined'"
            @click="Unreject(approvetObject.id)"
            >{{ $t("Unreject") }}</v-btn
          >
          <v-btn
            v-if="item_status == 'Approved'"
            class="modal-btn-save"
            @click="unapprove(approvetObject.id)"
            >{{ $t("Unapprove") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "LeaveRequests",
  mixins: [validationMixin],
  // components: { CreateLeaveRequest },
  data() {
    return {
      valid: false,
      approveDialog: false,
      unApproveDialog: false,
      approvePermissionDialog: false,
      item_status: "",
      typesItems: [
        { text: this.$i18n.t("Vacation"), value: "vacation" },
        { text: this.$i18n.t("Permission"), value: "permission" },
      ],
      approvetObject: {
        id: "",
        paid_days: "",
        apptoveType: "",
        approve_notes: "",
      },
      statusItems: [
        {
          text: this.$i18n.t("Ongoing"),
          value: "ongoing",
        },
        { text: this.$i18n.t("Pending"), value: "pending" },
        { text: this.$i18n.t("Declined"), value: "declined" },
        { text: this.$i18n.t("Approved"), value: "approved" },
        { text: this.$i18n.t("Expired"), value: "expired" },
        { text: this.$i18n.t("Ended"), value: "ended" },
      ],
      jobsItems: [],
      headers: [
        {
          text: this.$i18n.t("Request Code"),
          align: "start",
          sortable: false,
          value: "request_code",
        },
        {
          text: this.$i18n.t("Employee Name"),
          value: "employee",
          sortable: false,
        },
        {
          text: this.$i18n.t("Request Type"),
          value: "request_type",
          sortable: false,
        },
        {
          text: this.$i18n.t("Job Title"),
          value: "job_title",
          sortable: false,
        },
        { text: this.$i18n.t("Reason"), value: "reason", sortable: false },
        { text: this.$i18n.t("#Days"), value: "days", sortable: false },
        {
          text: this.$i18n.t("Start Day"),
          value: "start_date",
          sortable: false,
        },
        { text: this.$i18n.t("End Day"), value: "end_date", sortable: false },
        { text: this.$i18n.t("Status"), value: "status", sortable: false },
        {
          text: this.$i18n.t("#Days Remaining"),
          value: "dayes_remaining",
          sortable: false,
        },
        { text: this.$i18n.t("Notes"), value: "notes", sortable: false },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      requestObject: {
        id: "",
        startDate: "",
        endDate: "",
        reason_id: "",
        notes: "",
        files: [],
        fileNames: "",
      },
      snack: "",
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      options: {
        itemsPerPage: 15,
      },
      filter: {
        types: [],
        status: [],
        reasons: [],
        permissionReason: [],
        jobs: [],
        selectedYear: "",
        selectedMonth: "",
      },
      search: "",
      circle: true,
      page: 1,
      length: "",
      totalItems: "",
      totalVisible: "",
      filterMenu: false,
      days: "",
      duration: "",
      remaining: "",
      permissionReason: [],
      reasonsItem: [],
      permissionReasonsItem: [],
      years: [],
      months: [],
    };
  },
  watch: {
    CreateRequestDialog: {
      handler() {
        this.activateDatePicker();
      },
    },

    page: function() {
      this.getData(this.page);
    },

    search: {
      handler() {
        this.page = 1;
        this.getData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getData(this.page);
      },
      deep: true,
    },
  },

  methods: {
    closeDialogUnApprove() {
      this.approvetObject = {
        id: "",
        paid_days: "",
        apptoveType: "",
        approve_notes: "",
      };
      this.item_status = "";
      this.unApproveDialog = false;
    },
    closeDialog() {
      this.days = "";
      this.duration = "";
      this.remaining = "";
      this.$refs.form.resetValidation();
      this.approvetObject = {
        id: "",
        paid_days: "",
        apptoveType: "",
        approve_notes: "",
      };
      this.approveDialog = false;
      this.approvePermissionDialog = false;
    },
    openAproveDialog(item) {
      if (item.request_type == "vacation") {
        this.getRemainingReasons(item.reason_id);
        // console.log(Number(item.dayes_remaining));
        if (Number(item.dayes_remaining) < 1) {
          this.approvetObject.paid_days = 0;
          this.approvetObject.apptoveType = "unpaid";
        } else if (Number(item.days) > Number(item.dayes_remaining)) {
          this.approvetObject.apptoveType = "partialy_paid";
          this.approvetObject.paid_days = Number(item.dayes_remaining);
        } else if (Number(item.days) <= Number(item.dayes_remaining)) {
          this.approvetObject.apptoveType = "paid";
          this.approvetObject.paid_days = Number(item.days);
        }

        this.approvetObject.id = item.id;
        this.approveDialog = true;
      } else {
        this.approvetObject.id = item.id;
        this.approvetObject.apptoveType = "unpaid";
        this.approvePermissionDialog = true;
      }
    },
    openUnApprove(item) {
      this.approvetObject.id = item.id;

      this.item_status = item.status;
      this.unApproveDialog = true;
    },
    getRemainingReasons(id) {
      axios
        .get(this.getApiUrl + "/requestleave/getRemainingReasons/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.showReaminng = true;
          this.duration = response.data.data.duration;
          this.days = response.data.data.days;
          this.remaining = response.data.data.remaining;
          this.approvetObject.paid_days = response.data.data.remaining;
        });
    },
    unapprove(id) {
      axios
        .get(this.getApiUrl + "/requestleave/unapprove/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          console.log(response);
          this.closeDialogUnApprove();
          this.getData(this.page, "all");
        });
    },
    Unreject(id) {
      axios
        .get(this.getApiUrl + "/requestleave/Unreject/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          console.log(response);
          this.closeDialogUnApprove();
          this.getData(this.page, "all");
        });
    },
    reject(id) {
      axios
        .get(this.getApiUrl + "/requestleave/declined/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          console.log(response);
          this.closeDialog();
          this.getData(this.page, "all");
        });
    },
    approve() {
      axios
        .post(
          this.getApiUrl + "/requestleave/approve/" + this.approvetObject.id,
          this.approvetObject,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.closeDialog();
          this.getData(this.page, "all");
        });
    },
    changeStatus(item, status) {
      axios
        .get(this.getApiUrl + "/requestleave/" + status + "/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          console.log(response);
          this.getData(this.page);
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getData(page = null, dataFilter = "all") {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/requestleave/allRequests?dateflter=" +
            dataFilter +
            "&dashboad=true" +
            "&year=" +
            this.filter.selectedYear +
            "&month=" +
            this.filter.selectedMonth +
            "&search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
        });
    },
    getReasons() {
      axios
        .get(this.getApiUrl + "/requestleave/getReasons", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.reasonsItem = response.data.data;
        });
    },
    getPermissionsReasons() {
      axios
        .get(this.getApiUrl + "/requestleave/getPermissionsReasons", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.permissionReasonsItem = response.data.data;
        });
    },
    getJobs() {
      axios
        .get(this.getApiUrl + "/requestleave/getJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobsItems = response.data.data;
        });
    },

    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
  },

  mounted() {
    $(".custom-filter").on("click", function() {
      $(this)
        .addClass("active")
        .siblings()
        .removeClass("active");
    });

    this.getReasons();
    this.getPermissionsReasons();
    this.getJobs();

    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getData(this.page);
    } else {
      if (this.$route.query.vacationrequest) {
        console.log("hello world");
        this.filter.types = ["vacation"];
      } else if (this.$route.query.permissionRequest) {
        this.filter.types = ["permission"];
      } else {
        this.page = 1;
        this.getData(this.page);
      }
    }

    this.getYears();
    this.getMonths();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.btn-attach-files {
  display: block;
  cursor: pointer;
  padding: 10px;
  background: #7297fe;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  font-weight: 600;
}
.myFileBrowser {
  opacity: 0;
  position: absolute;
  left: 0;
  z-index: 999;
}
.days {
  margin: 0 auto;
  text-align: center;
  .dayUsed,
  .dayRemaining {
    display: inline-block;
    width: 40%;
    background-color: #b9cbff;
    color: #ffffff;
    padding: 0.5rem;
    border-radius: 5px;
  }
}
.status-blue {
  color: #7297ff;
}
.status-yellow {
  color: #ffb400;
}
.status-red {
  color: #ff8972;
}
.status-green {
  color: #5fb870;
}
.custom-filter {
  cursor: pointer;
  padding: 8px 12px;
  color: #888;
  display: inline-block;
  margin-right: 20px;
  border-radius: 15px;
}
.custom-filter.active {
  background: #7297ff;
  color: #ffff;
}
</style>

<template>
  <v-card max-width="100%" class="px-4 py-2">
    <v-row>
      <h3 class="main-color card-title">{{ $t("Employees") }}</h3>
    </v-row>

    <v-row>
      <v-data-table
        :options.sync="options"
        :headers="employeesheaders"
        :items="items"
        :items-per-page="15"
        class="elevation-1"
        hide-default-footer
      >
        <!-- <template v-slot:item.name={item}>
                <v-img max-width="25px" class="d-inline-block mx-1" src="../../assets/avatar.png" />
                <span>{{item.name}}</span>
              </template>
              -->
        <template v-slot:item.code="{ item }">
          <router-link :to="'/employees/show/' + item.id"
            ><p style="color: #7296fb">{{ item.code }}</p></router-link
          >
        </template>

        <template v-slot:item.citizen_id_expiry_date_gregorian="{ item }">
          <v-tooltip bottom max-width="2800px">
            <template v-slot:activator="{ on }">
              <p dark v-on="on">
                <v-icon
                  :color="getExpireDateColor(item.expire_class)"
                  dark
                  v-if="item.citizen_id_expiry_date_gregorian != null"
                  :title="item.expire_title"
                  >calendar_today</v-icon
                >
                {{ item.citizen_id_expiry_date_gregorian.slice(0, 20) }}
                <span
                  v-if="item.citizen_id_expiry_date_gregorian == null"
                  :title="item.expire_title"
                  >N/A</span
                >
              </p>
            </template>
            <span>{{ item.citizen_id_expiry_date_gregorian }}</span>
          </v-tooltip>
        </template>

        <template v-slot:item.contractStatus="{ item }">
          <v-stepper alt-labels v-model="item.contractStatus">
            <v-stepper-header>
              <!-- <v-stepper-step step="1">
                {{ item.contractStatus }}
              </v-stepper-step> -->

              <!-- <v-divider></v-divider> -->

              <v-stepper-step step="1" :complete="item.contractStatus >= 1">
                {{ $t("In Probation") }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" :complete="item.contractStatus >= 2">
                {{ $t("Contracted") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="3" :complete="item.contractStatus >= 3">
                {{ $t("Terminated") }}
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            @click="openExtent(item)"
            v-if="!item.is_contract"
            style="cursor: pointer"
            title="Extend Or Pass probation"
            >autorenew</v-icon
          >
          <v-icon
            v-if="
              item.is_contract &&
                checkContractEndDateValid(item.end_contract) &&
                !item.is_end_contract
            "
            style="cursor: pointer"
            title="Extend Contract"
            @click="openContract(item)"
            >autorenew</v-icon
          >

          <v-icon
            style="cursor: pointer"
            @click="openEndContractDialog(item)"
            v-if="!item.is_end_contract"
            title="End contact"
            >not_interested</v-icon
          >

          <v-icon
            style="cursor: pointer"
            disabled
            v-if="item.is_end_contract"
            title="End contact"
            >not_interested</v-icon
          >
          <v-icon
            class="main-color"
            title="Print Employee Information"
            @click="prentEmployeePDF(item.id)"
            style="cursor: pointer;r"
            >print</v-icon
          >
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
        class="no-padding"
        style="padding-left: 0 !important"
      >
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <!-- delete salary job -->
    <v-dialog
      v-model="openConfirmDialog"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to Delete this Permission Reason ?")
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="openConfirmDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editItem.id)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Extend  -->
    <v-dialog v-model="extendProbationDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Extend or Pass Probation") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="text-center">
                      <v-row>
                        <v-col cols="12" md="12" class="">
                          <div
                            :class="{ active: tab2 == 0 }"
                            class="imageDiv text-center"
                            @click="tab2 = 0"
                            style="width: 50%; margin: 10px auto; padding: 10px"
                          >
                            <v-icon style="cursor: pointer" class="main-color"
                              >autorenew</v-icon
                            >
                          </div>
                        </v-col>
                        <v-col cols="12" md="12">
                          <div class="content main-color">
                            {{ $t("Extend Probation") }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="text-center">
                      <v-row>
                        <v-col cols="12" md="12">
                          <div
                            :class="{ active: tab2 == 1 }"
                            class="imageDiv text-center"
                            @click="tab2 = 1"
                            style="width: 50%; margin: 10px auto; padding: 10px"
                          >
                            <i
                              style="cursor: pointer"
                              class="fa fa-file-contract fa-lg main-color"
                            ></i>
                          </div>
                        </v-col>
                        <v-col cols="12" md="12">
                          <div class="content main-color">
                            {{ $t("Pass Probation") }}
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>

                <v-card id="printTabsCard">
                  <v-row v-if="tab2 == 0">
                    <v-form
                      ref="form"
                      v-model="valid"
                      v-if="can_extend"
                      style="width: 100%"
                    >
                      <v-col cols="12" md="12">
                        <v-row>
                          <v-col cols="12" md="12">
                            <div>
                              <p class="value">{{ $t("Extend To") }}</p>
                            </div>
                            <v-text-field
                              id="extend_todat"
                              append-icon="calendar_today"
                              :rules="[validationRules.required]"
                              autocomplete="off"
                              v-model="extend_to"
                              solo
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div>
                              <p class="value">
                                {{ $t("Duration") }}
                                <span style="color: #888">
                                  ( {{ $t("Days") }} )
                                </span>
                              </p>
                            </div>
                            <v-text-field
                              :rules="[
                                validationRules.positiveFloatNumber,
                                validationRules.required
                              ]"
                              v-model="duration"
                              solo
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div>
                              <p class="value">{{ $t("Reason") }}</p>
                            </div>
                            <v-autocomplete
                              v-model="extend_reason"
                              solo
                              :items="['Reason1', 'Reason2', 'Other']"
                              required
                              @change="hideLabel = true"
                              :rules="[validationRules.required]"
                              chips
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row v-if="extend_reason == 'Other'">
                          <v-col cols="12" md="12">
                            <div>
                              <p class="value">{{ $t("Notes") }}</p>
                            </div>
                            <v-textarea
                              solo
                              :rules="[validationRules.required]"
                              name="input-7-4"
                              v-model="extend_notes"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-col>
                      <!--actions -->
                      <v-col cols="12" md="12" class="text-right">
                        <v-btn
                          class="modal-btn-cancel cancelBtn mr-2"
                          @click="closeExtendProbationDialog"
                          >{{ $t("Cancel") }}</v-btn
                        >
                        <v-btn
                          class="modal-btn-save saveBtn"
                          @click="extendProbation()"
                          >{{ $t("Confirm") }}</v-btn
                        >
                      </v-col>
                    </v-form>
                    <v-row v-else>
                      <v-col cols="12" md="12">
                        <p class="value">{{ $t("Can't Extend Probation") }}</p>
                      </v-col>
                      <v-col cols="12" md="12" class="text-right">
                        <v-btn
                          class="modal-btn-cancel cancelBtn mr-2"
                          @click="closeExtendProbationDialog"
                          >{{ $t("Cancel") }}</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-row>
                  <!-- pass-->
                  <v-row v-if="tab2 == 1">
                    <v-col cols="12" md="12">
                      <!-- item -->
                      <v-row>
                        <v-col cols="12" md="6">
                          <p class="value">{{ $t("Start Contract") }}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="value">{{ start_contract }}</p>
                        </v-col>
                      </v-row>

                      <!-- item -->
                      <v-row>
                        <v-col cols="12" md="6">
                          <p class="value">{{ $t("End Contract") }}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p class="value">{{ end_contract }}</p>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="12">
                          <div>
                            <p class="value">{{ $t("Notes") }}</p>
                          </div>

                          <v-textarea
                            solo
                            name="input-7-4"
                            v-model="pass_notes"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="text-right">
                      <v-btn
                        class="modal-btn-cancel cancelBtn mr-2"
                        @click="closeExtendProbationDialog"
                        >{{ $t("Cancel") }}</v-btn
                      >
                      <v-btn
                        class="modal-btn-save saveBtn"
                        @click="PassProbation()"
                        >{{ $t("Confirm") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- End Contract Step One  -->
    <v-dialog v-model="endContractDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{ $t("End Contract") }}</v-card-title>

        <v-card-text
          >{{ $t("Are you sure you want to End") }}
          <strong>"{{ item_name }}"</strong>
          {{
            $t(
              "Contract ? Please Note That Ending The Contract Marks This Employee Inactive"
            )
          }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="endContractDialog = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("cancel") }}</v-btn
          >

          <v-btn
            id="yesBtn"
            @click="openEndContractDialogStep2"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- End Contract Step Tow  -->
    <v-dialog v-model="endContractDialogStep2" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >{{ $t("End Contract") }}
          <p style="color: #888; font-size: 16px">
            {{ $t("Specify The Reason For Ending The Contract") }}
          </p></v-card-title
        >

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <div>
                <p class="value">{{ $t("Reason") }}</p>
              </div>
              <v-autocomplete
                v-model="end_contract_reason"
                solo
                :items="['Reason1', 'Reason2', 'Other']"
                required
                @change="hideLabel = true"
                :rules="[validationRules.required]"
                chips
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" v-if="end_contract_reason == 'Other'">
              <div>
                <p class="value">{{ $t("Notes") }}</p>
              </div>
              <v-textarea
                solo
                :rules="[validationRules.required]"
                name="input-7-4"
                v-model="end_contract_notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="closeEndContractDialogStep2"
            id="noBtn"
            ref="always_focus"
            >{{ $t("cancel") }}</v-btn
          >

          <v-btn
            id="yesBtn"
            @click="endContract"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Extend Contract -->
    <v-dialog v-model="extendContractDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Extend Contract") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="space">
              <v-col cols="12" sm="12" md="12">
                <v-form ref="form" v-model="valid" style="width: 100%">
                  <v-col cols="12" md="12">
                    <v-row>
                      <v-col cols="12" md="12">
                        <div>
                          <p class="value">{{ $t("Extend To") }}</p>
                        </div>
                        <v-text-field
                          id="extend_contract_date"
                          append-icon="calendar_today"
                          :rules="[validationRules.required]"
                          autocomplete="off"
                          v-model="extend_contract.contract_end_date"
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <div>
                          <p class="value">{{ $t("Job Title") }}</p>
                        </div>

                        <v-autocomplete
                          :items="jobs"
                          @change="hideLabel = true && select(jobOpject.job_id)"
                          item-text="name"
                          item-value="id"
                          v-model="extend_contract.job_id"
                          :rules="[validationRules.required]"
                          solo
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" md="12">
                        <div>
                          <p class="value">{{ $t("Salary") }}</p>
                        </div>

                        <v-text-field
                          :rules="[
                            validationRules.required,
                            validationRules.floatNumberNotZero
                          ]"
                          v-model="extend_contract.basic_salary"
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div>
                          <p class="value">{{ $t("Allowances") }}</p>
                        </div>

                        <v-autocomplete
                          :items="allowancesItems"
                          @change="
                            hideLabel =
                              true && onSelect(extend_contract.allowaIds)
                          "
                          item-text="name"
                          item-value="id"
                          multiple
                          v-model="extend_contract.allowaIds"
                          chips
                          :rules="[validationRules.required]"
                          solo
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <div v-if="extend_contract.allowances.length > 0">
                      <v-row class="form-item">
                        <v-col
                          v-for="(allowance,
                          index) in extend_contract.allowances"
                          :key="index"
                          cols="12"
                        >
                          <div>
                            <span class="allowance-title">{{
                              allowance.name
                            }}</span>
                            <v-row>
                              <v-col cols="12" md="7">
                                <v-text-field
                                  class="allowance-input"
                                  v-if="allowance.account_type == 'amount'"
                                  v-model="allowance.value"
                                  :rules="[
                                    validationRules.required,
                                    validationRules.numericNotZero,
                                    validationRules.maxLength10
                                  ]"
                                ></v-text-field>
                                <v-text-field
                                  class="allowance-input"
                                  v-else
                                  v-model="allowance.value"
                                  :rules="[
                                    validationRules.required,
                                    validationRules.percentage
                                  ]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <span
                                  v-if="allowance.account_type == 'amount'"
                                  class="type"
                                  >{{ $t("SAR") }}</span
                                >
                                <span v-else class="type">%</span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <!--actions -->
                  <v-col cols="12" md="12" class="text-right">
                    <v-btn
                      class="modal-btn-cancel cancelBtn mr-2"
                      @click="closeExtendContractDialog"
                      >{{ $t("Cancel") }}</v-btn
                    >
                    <v-btn
                      class="modal-btn-save saveBtn"
                      @click="extendContract()"
                      >{{ $t("Confirm") }}</v-btn
                    >
                  </v-col>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      {{ snackText }}
      <v-btn color="pink" text @click="snackbar = false">{{
        $t("Close")
      }}</v-btn>
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");

import { validationMixin } from "../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  data() {
    return {
      extendContractDialog: false,
      snackbar: false,
      snackText: "",
      snackColor: "",
      filterMenu: false,
      valid: true,
      balance: "",
      timeout: 600,
      loading: false,
      extendProbationDialog: false,
      endContractDialog: false,
      endContractDialogStep2: false,
      tab: 0,
      tab2: 0,
      // extentTab: true,
      // passTab: false,
      page: 1,

      dayesValid: false,
      dialogReset: false,

      openConfirmDialog: false,
      opendeleteErrorialog: false,
      allowancesItems: [],
      extend_contract: {
        contract_end_date: "",
        contract_end_date_hijri: "",
        job_id: "",
        basic_salary: "",
        allowaIds: [],
        allowances: []
      },
      jobs: [],
      employeesheaders: [
        {
          text: this.$i18n.t("Code"),
          value: "code",
          sortable: true
        },
        {
          text: this.$i18n.t("Name"),
          value: "name",
          sortable: true
        },
        {
          text: this.$i18n.t("Job Title"),
          value: "job_title",
          sortable: false
        },

        {
          text: this.$i18n.t("Nationality"),
          value: "Nationality",
          sortable: true
        },

        {
          text: this.$i18n.t("Expiration date"),
          value: "citizen_id_expiry_date_gregorian",
          sortable: false
        },
        {
          text: this.$i18n.t("Status"),
          value: "contractStatus",
          sortable: false
        },

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false }
      ],
      circle: true,

      length: "",
      totalVisible: "",
      totalItems: "",
      items: [],
      nationalities: [],
      shifts: [],
      extend_to: "",
      extend_to_hijri: "",
      duration: "",
      extend_reason: "Other",
      extend_notes: "",
      pass_notes: "",
      start_contract: "",
      end_contract: "",
      date_end_probation: "",
      can_extend: "",
      end_contract_notes: "",
      end_contract_reason: "Other",
      item_name: "",
      item_id: "",
      item_pass_propation: "",

      options: {
        itemsPerPage: 15
      }
    };
  },
  methods: {
    prentEmployeePDF(id) {
      axios
        .get(this.getApiUrl + "/employee/printEmployeeProfile/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          var link = response.data.data;
          // console.log(link);
          window.open(link, "_blank");
        });
    },
    getExpireDateColor(color) {
      if (color == "red") return "error";
      else if (color == "yellow") return "yellow";
      else if (color == "green") return "success";
      else return "";
    },
    checkContractEndDateValid(endDate) {
      const today = moment().format("YYYY-MM-DD");
      var end = moment(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      var todayAdd90 = moment(today, "YYYY-MM-DD")
        .add(90, "days")
        .format("YYYY-MM-DD");
      // console.log(todayAdd90);
      return end > today && end < todayAdd90;
    },
    closeExtendContractDialog() {
      this.extendContractDialog = false;
      this.extend_contract = {
        contract_end_date: "",
        contract_end_date_hijri: "",
        job_id: "",
        basic_salary: "",
        allowaIds: [],
        allowances: []
      };
    },
    openContract(item) {
      this.getAllowances(item);
      this.item_id = item.id;
      this.extendContractDialog = true;
      this.extend_contract.contract_end_date = item.end_contract;
      this.extend_contract.job_id = item.job_id;
      this.extend_contract.basic_salary = item.basic_salary;
      this.extend_contract.allowaIds = item.allowances;
      // this.extend_contract.allowances = item.allowances;
      this.onSelect(this.extend_contract.allowaIds);
    },
    onSelect(object) {
      let types = [];
      object.forEach(element => {
        let indexid = this.allowancesItems.findIndex(p => p.id == element);
        let name = this.allowancesItems[indexid].name;
        let type = this.allowancesItems[indexid].type;
        let value = this.allowancesItems[indexid].value;
        let account_type = this.allowancesItems[indexid].account_type;
        let allowance_id = this.allowancesItems[indexid].id;
        // console.log(type);
        types.push({
          allowance_id: allowance_id,
          name: name,
          type: type,
          value: value,
          account_type: account_type
        });
      });
      this.extend_contract.allowances = types;
      // this.errorAllowancesItems = false;
    },
    getAllowances(item) {
      axios
        .get(
          this.getApiUrl +
            "/employee/getAllowances?nationality_id=" +
            item.nationality_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
              //the token is a variable which holds the token
            }
          }
        )
        .then(response => {
          this.allowancesItems = response.data.data;
          let _this = this;

          $("#extend_contract_date").calendarsPicker({
            minDate: 0,
            dateFormat: "dd/mm/yyyy",

            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.extend_contract.contract_end_date = dateH;
              } else {
                _this.extend_contract.contract_end_date = "";
              }
            }
          });
          // console.log(response);
        });
    },
    closeExtendProbationDialog() {
      this.extendProbationDialog = false;
      this.duration = "";
      this.extend_to = "";
      this.extend_reason = "";
      this.pass_notes = "";
      this.start_contract = "";
      this.end_contract = "";
      this.item_name = "";
      this.end_contract_notes = "";
      this.extend_notes = "";
    },
    openExtent(item) {
      // if(item.item_pass_propation)
      this.extendProbationDialog = true;
      this.duration = item.duration;
      this.item_id = item.id;
      this.extend_to = item.extend_to;
      this.start_contract = item.start_contract;
      this.end_contract = item.end_contract;
      this.can_extend = item.can_extend;
      this.date_end_probation = item.date_end_probation;
      this.extend_reason = "Other";
      this.activateDatePicker();
    },
    openEndContractDialog(item) {
      this.endContractDialog = true;
      this.item_id = item.id;
      this.item_name = item.name;
    },
    openEndContractDialogStep2() {
      this.endContractDialog = false;
      this.endContractDialogStep2 = true;
    },
    closeEndContractDialogStep2() {
      this.endContractDialogStep2 = false;
      this.item_name = "";
      this.end_contract_notes = "";
    },
    endContract() {
      const data = {
        end_contract_reason: this.end_contract_reason,
        end_contract_notes: this.end_contract_notes,
        extend_notes: this.extend_notes
      };

      axios
        .post(this.getApiUrl + "/employee/endContract/" + this.item_id, data, {
          headers: {
            Authorization: "Bearer " + localStorage.token
          }
        })
        .then(response => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.closeEndContractDialogStep2();
            location.reload();
            this.getAllData(this.page);
          }
        });
    },
    extendContract() {
      var m = moment_hijri(
        this.extend_contract.contract_end_date,
        "DD/MM/YYYY"
      );
      this.extend_contract.contract_end_date_hijri = m.format("iDD/iMM/iYYYY");

      axios
        .post(
          this.getApiUrl + "/employee/extendContract/" + this.item_id,
          this.extend_contract,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.closeExtendContractDialog();
            this.setPaginationParameters(response);
            this.totalItems = response.data.data.total;
            this.items = [];
            this.items = response.data.data.data;
            location.reload();
            this.loading = false;
          }
        });
    },
    extendProbation() {
      if (this.valid == false) {
        this.validate();
      } else {
        const data = {
          extend_to: this.extend_to,
          extend_to_hijri: this.extend_to_hijri,
          duration: this.duration,
          extend_reason: this.extend_reason
        };

        axios
          .post(
            this.getApiUrl + "/employee/extendProbation/" + this.item_id,
            data,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token
              }
            }
          )
          .then(response => {
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
            } else {
              this.closeExtendProbationDialog();
              location.reload();
              this.getAllData(this.page);
            }
          });
      }
    },
    PassProbation() {
      const data = {
        pass_notes: this.pass_notes
      };

      axios
        .post(
          this.getApiUrl + "/employee/passProbation/" + this.item_id,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          if (response.data.status.error == true) {
            Object.assign(
              this.validation_errors,
              response.data.status.validation_errors
            );
          } else {
            this.closeExtendProbationDialog();
            this.getAllData(this.page);
          }
        });
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function() {
        $("#extend_todat").calendarsPicker({
          dateFormat: "dd/mm/yyyy",

          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
              _this.extend_to = dateH;
            } else {
              _this.extend_to = "";
            }
          }
        });
      });
    },
    getExpireDateColor(color) {
      if (color == "red") return "error";
      else if (color == "yellow") return "yellow";
      else if (color == "green") return "success";
      else return "";
    },
    inputFocus() {
      $("#inpt_search")
        .parent("label")
        .addClass("active");
      $(".input_search")
        .parent("label")
        .addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search")
          .parent("label")
          .removeClass("active");
        $(".input_search")
          .parent("label")
          .removeClass("active");
      }
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      let sort_type = "";
      let column = "";

      axios
        .get(
          this.getApiUrl +
            "/employee?page=" +
            this.page +
            "&sort_type=" +
            sort_type +
            "&column=" +
            column,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token
            }
          }
        )
        .then(response => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
    getShifts() {
      axios
        .get(this.getApiUrl + "/employee/getShifts", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.shifts = response.data.data;
          // console.log(response);
        });
    },
    getJobs() {
      axios
        .get(this.getApiUrl + "/employee/allJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token
            //the token is a variable which holds the token
          }
        })
        .then(response => {
          this.jobs = response.data.data;
          // console.log(response);
        });
    },
    select(id) {
      let indexid = this.jobs.findIndex(p => p.id == id);
      let valuebaseSalary = this.jobs[indexid].base_salary;
      this.SalaryObject.basic_salary = valuebaseSalary;
      // console.log("select base is=>" + valuebaseSalary);
    }
  },
  mounted() {
    // this.activateDatePicker();

    this.page = 1;
    this.getAllData(this.page);

    axios
      .get(this.getApiUrl + "/getCountries", {
        headers: {
          Authorization: "Bearer " + localStorage.token
          //the token is a variable which holds the token
        }
      })
      .then(response => {
        this.nationalities = response.data.data;
      });
    this.getShifts();
    // this.getAllData();
    $(".imageDiv").on("click", function() {
      $(this)
        .addClass("active")
        .siblings()
        .removeClass("active");
    });
    this.getJobs();
  }
};
</script>

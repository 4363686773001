<template>
  <v-card max-width="100%" class="pa-4 employees">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h3 class="main-color card-title">{{ $t("Employees") }}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <div class="d-flex">
          <v-select
            :items="jobs"
            v-model="selectedJob"
            item-text="name"
            item-value="id"
            @change="getChartData()"
            class="mr-4"
            dense
            solo
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="6">
        <canvas id="student-doughnut-chart" height="221px"></canvas
      ></v-col>
      <v-col cols="12" sm="12" md="6">
        <table class="mt-16 chart-info">
          <tbody>
            <tr v-for="(item, index) in top4EmployeeData" :key="index">
              <td class="">
                <div class="d-flex align-start">
                  <v-icon
                    :color="chartColors[index]"
                    class="dashboard-blue"
                    small
                    >trip_origin</v-icon
                  >
                  <span class="val mr-1">{{ labels[index] }} </span>
                </div>
              </td>

              <td>
                <span class="num"> {{ item }} </span>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Chart from "chart.js";
import axios from "axios";

export default {
  name: "doughnutChart",
  data() {
    return {
      // chart data
      labels: [
        this.$i18n.t("egypt"),
        this.$i18n.t("saudi"),
        this.$i18n.t("usa"),
        this.$i18n.t("Compansatory"),
      ],
      employeesData: [],
      top4EmployeeData: [],
      chartColors: [],
      jobs: [],
      selectedJob: "",
      employeeChart: "",
    };
  },
  methods: {
    getJobs() {
      axios
        .get(this.getApiUrl + "/employee/allJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobs = response.data.data;
          this.selectedJob = this.jobs[0].id;
          this.getChartData();
        });
    },
    getChartData() {
      let jobId = "";
      if (!this.selectedJob) {
        jobId = this.jobs[0].id;
      } else {
        jobId = this.selectedJob;
      }
      axios
        .get(this.getApiUrl + "/hrConfigration/FilterByJobTitle/" + jobId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.employeesData = response.data.data;

          this.employeesData = response.data.data.data;
          this.labels = response.data.data.labels;

          if (this.employeesData.length > 4) {
            this.employeesData.forEach((item, index) => {
              if (index < 4) {
                this.top4EmployeeData.push(item);
              }
            });
          } else {
            this.top4EmployeeData = this.employeesData;
          }

          let colorItem;
          this.chartColors = [];
          this.employeesData.forEach((item, index) => {
            colorItem = this.getRandomColor(index);
            this.chartColors.push(colorItem);
          });

          this.createDoughnutChart();
        });
    },
    createDoughnutChart() {
      if (this.employeeChart) {
        this.employeeChart.destroy();
      }
      if (this.employeesData.length > 0) {
        /*-------------------------------------
                    doughnut Chart 
                -------------------------------------*/
        const myChart = document
          .getElementById("student-doughnut-chart")
          .getContext("2d");
        const total = this.employeesData.length;
        const title = "Employees";

        // eslint-disable-next-line no-unused-vars
        this.employeeChart = new Chart(myChart, {
          type: "doughnut", // "bar", "horizontalBar", "doughnut", "line", "pie", "radar", "polarArea"
          data: {
            datasets: [
              {
                backgroundColor: this.chartColors,
                data: this.employeesData,
              },
            ],

            // These labels appear in the legend and in the tooltips when hovering different arcs
            labels: this.labels,
          },
          plugins: [
            {
              beforeDraw: function(chart) {
                if (chart.config.options.elements.center) {
                  // Get ctx from string
                  const ctx = chart.chart.ctx;

                  // Get options from the center object in options
                  const centerConfig = chart.config.options.elements.center;
                  const fontStyle = centerConfig.fontStyle || "Arial";
                  const txt = total + " " + title;
                  const color = centerConfig.color || "#000";
                  const maxFontSize = 12 || 75;
                  const sidePadding = centerConfig.sidePadding || 20;
                  const sidePaddingCalculated =
                    (sidePadding / 100) * (chart.innerRadius * 2);
                  // Start with a base font of 30px
                  ctx.font = "12px " + fontStyle;

                  // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
                  const stringWidth = ctx.measureText(txt).width;
                  const elementWidth =
                    chart.innerRadius * 2 - sidePaddingCalculated;

                  // Find out how much the font can grow in width.
                  // eslint-disable-next-line no-unused-vars
                  const widthRatio = elementWidth / stringWidth;
                  const newFontSize = "12px";
                  const elementHeight = chart.innerRadius * 2;

                  // Pick a new font size so it will not be larger than the height of label.
                  let fontSizeToUse = Math.min(
                    newFontSize,
                    elementHeight,
                    maxFontSize
                  );
                  let minFontSize = centerConfig.minFontSize;
                  const lineHeight = centerConfig.lineHeight || 25;
                  let wrapText = false;

                  if (minFontSize === undefined) {
                    minFontSize = 20;
                  }

                  if (minFontSize && fontSizeToUse < minFontSize) {
                    fontSizeToUse = minFontSize;
                    wrapText = true;
                  }

                  // Set font settings to draw it correctly.
                  ctx.textAlign = "center";
                  ctx.textBaseline = "middle";
                  const centerX =
                    (chart.chartArea.left + chart.chartArea.right) / 2;
                  let centerY =
                    (chart.chartArea.top + chart.chartArea.bottom) / 2;
                  ctx.font = 10 + "px " + fontStyle;
                  ctx.fillStyle = color;

                  if (!wrapText) {
                    ctx.fillText(txt, centerX, centerY);
                    return;
                  }

                  const words = txt.split(" ");
                  let line = "";
                  const lines = [];

                  // Break words up into multiple lines if necessary
                  for (let n = 0; n < words.length; n++) {
                    const testLine = line + words[n] + " ";
                    const metrics = ctx.measureText(testLine);
                    const testWidth = metrics.width;
                    if (testWidth > elementWidth && n > 0) {
                      lines.push(line);
                      line = words[n] + " ";
                    } else {
                      line = testLine;
                    }
                  }

                  // Move the center up depending on line height and number of lines
                  centerY -= (lines.length / 2) * lineHeight;

                  for (let n = 0; n < lines.length; n++) {
                    ctx.fillText(lines[n], centerX, centerY);
                    centerY += lineHeight;
                  }
                  //Draw text in center
                  ctx.fillText(line, centerX, centerY);
                }
              },
            },
          ],
          options: {
            responsive: true,
            maintainAspectRatio: false,

            animation: {
              duration: 1000,
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
            },
            elements: {
              center: {
                color: "#FF6384", // Default is #000000
                fontStyle: "Arial", // Default is Arial
                sidePadding: 20, // Default is 20 (as a percentage)
                minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
                lineHeight: 25, // Default is 25 (in px), used for when text wraps
              },
            },
          },
        });
      }
    },

    getRandomColor(index) {
      let colorArray = [
        "#7297FF",
        "#56d9fe",
        "#FFB400",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99FF99",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#66664D",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ];

      return colorArray[index];
    },
  },
  mounted() {
    this.getJobs();
  },
};
</script>

<style lang="scss" scoped>
.chart-info {
  .val {
    font-size: 14px !important;
  }
  .num {
    font-size: 14px !important;
  }
}

.employees,
.salaries,
.leave-request,
.leave-stats,
.allowances,
.employee-holidays {
  height: 100% !important;
}

@media (max-width: 600px) {
  #student-doughnut-chart {
    width: 180px !important;
    height: 180px !important;
  }
}
</style>

<template>
  <v-card max-width="100%" class="px-4 py-2 first-card">
    <v-row>
      <v-col cols="12" sm="12" md="12" class="pb-0">
        <h3 class="main-color card-title">{{ $t("Total Salaries") }}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="by-0">
        <div class="d-flex">
          <div class="d-flex">
            <v-select
              :label="$i18n.t('Year')"
              v-model="selectedYear"
              item-text="year"
              item-value="year"
              :items="years"
              @change="getTotalSalaries()"
              class="px-2"
              solo
              dense
            ></v-select>

            <v-select
              :label="$i18n.t('Month')"
              v-model="selectedMonth"
              :items="months"
              item-text="month"
              item-value="id"
              @change="getTotalSalaries()"
              class="px-2"
              dense
              solo
            ></v-select>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="text-center pa-1">
        <p class="num mb-0">{{ totalSalaries.deductions }}</p>
        <p class="text grey--text">{{ $t("Deductions") }}</p>
      </v-col>
      <v-col cols="6" class="text-center pa-1">
        <p class="num mb-0">{{ totalSalaries.allowances }}</p>
        <p class="text grey--text">{{ $t("Allowances") }}</p>
      </v-col>
      <v-col cols="6" class="text-center pa-1">
        <p class="num mb-0">{{ totalSalaries.gross }}</p>
        <p class="text grey--text">{{ $t("Gross") }}</p>
      </v-col>
      <v-col cols="6" class="text-center pa-1">
        <p class="num mb-0">{{ totalSalaries.net }}</p>
        <p class="text grey--text">{{ $t("Net Salary") }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import axios from "axios";

export default {
  name: "hrTotalSalaries",
  data() {
    return {
      nationalities: [],
      totalSalaries: { allownces: "", deductions: "", net: "", gross: "" },
      years: [],
      months: [],
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: new Date().getMonth() + 1,
    };
  },
  methods: {
    getTotalSalaries() {
      axios
        .post(
          this.getApiUrl + "/salaries/getTotalSalaries",
          {
            month: this.selectedMonth,
            year: this.selectedYear,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.totalSalaries = response.data.data;
        });
    },

    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
  },
  mounted() {
    this.getYears();
    this.getMonths();
    this.getTotalSalaries();
  },
};
</script>

<style lang="scss" scoped>
.first-card {
  height: 45%;
  min-height: 300px;
}
</style>
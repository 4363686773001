<template>
  <v-card max-width="100%" class="pa-4 salaries">
    <v-row>
      <v-col cols="12" sm="12" md="12" class="pb-0">
        <h3 class="main-color card-title">{{ $t("Salaries") }}</h3>
      </v-col>
      <v-col cols="12" sm="12" md="12" class="py-0">
        <div class="d-flex">
          <v-select
            :label="$i18n.t('Year')"
            v-model="selectedYear"
            item-text="year"
            item-value="year"
            :items="years"
            class="px-2"
            @change="getTotalSalariesByJob()"
            solo
            dense
          ></v-select>

          <v-select
            :label="$i18n.t('Month')"
            v-model="selectedMonth"
            :items="months"
            item-text="month"
            item-value="id"
            class="px-2"
            @change="getTotalSalariesByJob()"
            dense
            solo
          ></v-select>
        </div>
      </v-col>
    </v-row>

    <v-row v-for="(job, index) in totalSalariesByJob" :key="index">
      <v-col cols="3" class="px-0">
        <p>{{ job.job }}</p>
      </v-col>
      <v-col cols="6" class="px-0">
        <v-progress-linear
          :color="job.color"
          rounded
          :value="job.value"
          height="15px"
        ></v-progress-linear>
        <p></p>

        <p class="mb-0 stats">
          <span class="mb-0 grey--text progress-info"
            >{{ $t("Allowances") }}: {{ job.allowances }}K
          </span>
          <span class="mb-0 grey--text progress-info"
            >{{ $t("Deductions") }}: {{ job.deductions }}K
          </span>
          <span class="mb-0 grey--text progress-info"
            >{{ $t("Gross") }}: {{ job.gross }}{{ $t("K") }}
          </span>
        </p>
      </v-col>
      <v-col cols="3" class="px-0">
        <p class="text-center">{{ job.net }}{{ $t("SAR") }}</p>
      </v-col>
    </v-row>

    <v-row v-if="totalSalariesByJob.length == 0">
      <v-col>
        <v-alert dense type="info" class="mt-8">
          {{ $t("There is no data available") }}
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  name: "hrSalaries",
  data() {
    return {
      totalSalariesByJob: [],
      years: [],
      months: [],
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: new Date().getMonth() + 1,
    };
  },
  methods: {
    getTotalSalariesByJob() {
      axios
        .post(
          this.getApiUrl + "/salaries/getTotalSalariesByJob",
          {
            month: this.selectedMonth,
            year: this.selectedYear,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.totalSalariesByJob = response.data.data;
        });
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.years = response.data.data;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.months = response.data.data;
        });
    },
  },
  mounted() {
    this.getTotalSalariesByJob();
    this.getYears();
    this.getMonths();
  },
};
</script>
<style lang="scss" scoped>
.stats {
  font-size: 12px !important;
}
</style>
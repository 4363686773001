<template>
  <v-card max-width="100%" class="px-4 py-2 allowances">
    <v-row>
      <v-col cols="12" sm="5">
        <h3 class="main-color card-title">{{ $t("Allowances") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="text-center error--text pa-0" v-if="periodError">
          {{ periodError }}
        </p>
      </v-col>

      <v-row>
        <v-col cols="6">
          <label>{{ $t("from") }}</label>
        </v-col>
        <v-col cols="6">
          <label>{{ $t("to") }}</label>
        </v-col>
      </v-row>

      <v-col cols="12" class="d-flex justify-space-between pa-0">
        <v-select
          :label="$i18n.t('From Year')"
          v-model="filters.selectedYearFrom"
          item-text="year"
          item-value="year"
          :items="yearsFrom"
          class="px-2"
          @change="
            periodValidation(
              filters.selectedMonthFrom,
              filters.selectedYearFrom,
              filters.selectedMonthTo,
              filters.selectedYearTo
            )
          "
          solo
          dense
        ></v-select>
        <v-select
          :label="$i18n.t('From Month')"
          v-model="filters.selectedMonthFrom"
          :items="monthsFrom"
          item-text="month"
          item-value="id"
          class="px-2"
          @change="
            periodValidation(
              filters.selectedMonthFrom,
              filters.selectedYearFrom,
              filters.selectedMonthTo,
              filters.selectedYearTo
            )
          "
          dense
          solo
        ></v-select>

        <v-select
          :label="$i18n.t('To Year')"
          v-model="filters.selectedYearTo"
          item-text="year"
          item-value="year"
          :items="yearsTo"
          class="px-2"
          @change="
            periodValidation(
              filters.selectedMonthFrom,
              filters.selectedYearFrom,
              filters.selectedMonthTo,
              filters.selectedYearTo
            )
          "
          solo
          dense
        ></v-select>
        <v-select
          :label="$i18n.t('To Month')"
          v-model="filters.selectedMonthTo"
          :items="monthsTo"
          item-text="month"
          item-value="id"
          class="px-2"
          @change="
            periodValidation(
              filters.selectedMonthFrom,
              filters.selectedYearFrom,
              filters.selectedMonthTo,
              filters.selectedYearTo
            )
          "
          dense
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <canvas id="allowance-chart" dir="rtl" height="350"></canvas>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "axios";

import Chart from "chart.js";

export default {
  name: "hrAllowance",
  data() {
    return {
      chart: "",
      allawance: [],
      labels: [],
      yearsFrom: [],
      monthsFrom: [],
      yearsTo: [],
      monthsTo: [],
      filters: {
        selectedYearFrom: "",
        selectedMonthFrom: "",
        selectedYearTo: "",
        selectedMonthTo: "",
      },
      periodError: "",
    };
  },
  watch: {
    filters: {
      handler() {
        if (!this.periodError) {
          this.getAllawance();
        }
      },
      deep: true,
    },
  },
  methods: {
    createLineChart() {
      let allawance = this.allawance;
      let labels = this.labels;
      /*-------------------------------------
            bar Chart 
        -------------------------------------*/
      const lineChart = document
        .getElementById("allowance-chart")
        .getContext("2d");

      if (this.chart) {
        this.chart.destroy();
      }

      // eslint-disable-next-line no-unused-vars
      this.chart = new Chart(lineChart, {
        type: "line", // "bar", "horizontalBar", "doughnut", "line", "pie", "radar", "polarArea"
        data: {
          labels: labels,

          datasets: allawance,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          // hover: { mode: null },
          layout: {
            padding: {
              left: 50,
            },
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  padding: 20,
                  margin: 20,
                  fontFamily: "FontAwesome",
                  autoSkip: false,
                  // maxRotation: 90,
                  // minRotation: 90,
                  fontColor: "#FFB400",
                  reverse: this.currentAppLocale == "en" ? false : true,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  // eslint-disable-next-line no-unused-vars
                  userCallback: function (label, index, labels) {
                    // when the floored value is the same as the value we have a whole number
                    if (Math.floor(label) === label) {
                      return label;
                    }
                  },
                },
                position: this.currentAppLocale == "en" ? "left" : "right",
              },
            ],
          },
          legend: {
            display: true,
          },
          tooltips: {
            enabled: true,
            titleFontFamily: "FontAwesome",
            bodyFontFamily: "FontAwesome",
          },
        },
      });
    },
    getAllawance() {
      axios
        .post(
          this.getApiUrl + "/salaries/getAllowncesStatictics",
          {
            from_month: this.filters.selectedMonthFrom,
            to_month: this.filters.selectedMonthTo,
            from_year: this.filters.selectedYearFrom,
            to_year: this.filters.selectedYearTo,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.allawance = response.data.data.data;
          this.labels = response.data.data.labels;

          this.createLineChart();
        });
    },
    getYears() {
      axios
        .get(this.getApiUrl + "/hrConfigration/getYears", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.yearsFrom = response.data.data;
          this.filters.selectedYearFrom = this.yearsFrom[0].year;
          this.yearsTo = response.data.data;
          this.filters.selectedYearTo = this.yearsTo[0].year;
        });
    },

    getMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.monthsFrom = response.data.data;
          this.filters.selectedMonthFrom = this.monthsFrom[0].id;
          this.monthsTo = response.data.data;
          this.filters.selectedMonthTo = this.monthsTo[0].id;
        });
    },

    periodValidation(monthFrom, yearFrom, monthTo, yearTo) {
      yearFrom = Number(yearFrom);
      yearTo = Number(yearTo);

      this.periodError = "";

      if (yearFrom > yearTo) {
        this.periodError = "To date should be large than from";
      } else if (yearFrom == yearTo) {
        if (monthFrom > monthTo) {
          this.periodError = "To date should be large than from";
        } else {
          this.periodError = "";
        }
      }
    },
  },
  mounted() {
    this.getYears();
    this.getMonths();
    // this.createLineChart();
  },
};
</script>
<style lang="scss" scoped>
.allowances {
  height: 100% !important;
}
</style>
